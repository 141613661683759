import { Component } from '@angular/core';
import { LayoutService } from "./service/app.layout.service";
import { AppComponent } from '../app.component';


@Component({
    selector: 'app-footer',
    templateUrl: './app.footer.component.html'
})
export class AppFooterComponent {
    version = this.appComponent.config.version;
    constructor(public layoutService: LayoutService,
        public appComponent: AppComponent) { }
}
