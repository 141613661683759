import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationService } from '../modules/components/auth/services/authorization.service';
import { LocalStorageService } from '../modules/components/auth/services/local-storage.service';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
} from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(
        private auth: AuthorizationService,
        private router: Router,
        private localService: LocalStorageService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // No interceptar oauth/token
        if (request.url.includes('/assets/config.json')|| request.url.includes('/assets/i18n/') || request.url.includes('/auth/login')
            || request.url.includes('/usuario/activate/') || request.url.includes('/usuario/register') || request.url.includes('/usuario/recover')) {
            return next.handle(request).pipe(catchError(this.cacharError.bind(this)));
        }
        // Agregar AccessToken
        if (!this.auth.isAuthenticated()) {
            this.router.navigate(['/auth/login']);
            return next.handle(request);
        }
        const accessToken = this.localService.getJsonValue('gestiontmx2023');
        if (accessToken != null && accessToken != undefined && !request.headers.get('Authorization')) {

            const reqClone = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${accessToken}`
                }
            });

            return next.handle(reqClone).pipe(catchError(this.cacharError.bind(this)));
        }
        return next.handle(request).pipe(catchError(this.cacharError.bind(this)));
    }

    cacharError = (error: HttpErrorResponse) => {
        console.log('errorrrr:', error);
        if (error instanceof HttpErrorResponse) {
            if (error.error instanceof ErrorEvent) {
                console.error('Error Event');
            } else {
                console.log(`error status : ${error.status} ${error.statusText}`);
                switch (error.status) {
                    case 401:      // login
                        this.router.navigate(['/auth/login']);
                        break;
                    case 403:     // forbidden
                        this.router.navigate(['/auth/access']);
                        break;
                }
            }
        } else {
            console.error('some thing else happened');
        }
        return throwError(error);
    }
}

