import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[] = [];

    constructor(public layoutService: LayoutService) { }

    ngOnInit() {
        const item = JSON.parse(localStorage.getItem('usuario'));
        if (item.tipoUsuario === 1) {
            this.model = [
                {
                    label: 'Inicio',
                    items: [
                        { label: 'Dashboard', icon: 'pi pi-fw pi-home', routerLink: ['/dash'] }
                    ]
                },
                {
                    label: 'Entrega - Recepción',
                    items: [
                        { label: 'Entrega', icon: 'pi pi-fw pi-user-minus', routerLink: ['/solicitud/entrega'] },
                        /*{ label: 'El que recibe', icon: 'pi pi-fw pi-user-plus', routerLink: ['/solicitud/recibe'] },
                        { label: 'Testigos', icon: 'pi pi-fw pi-users', routerLink: ['/solicitud/testigo'] },*/
                        { label: 'Solicitudes', icon: 'pi pi-fw pi-book', routerLink: ['/solicitud/consulta'] },

                    ]
                },
                {
                    label: 'Contraloria',
                    items: [
                        { label: 'Solicitudes', icon: 'pi pi-fw pi-file', routerLink: ['/contralor/listadoContralor'] }
                    ]
                },
                    {
                        label: 'ESAF',
                        items: [
                            { label: 'Solicitudes', icon: 'pi pi-fw pi-file', routerLink: ['/esaf/listado'] },
                            { label: 'Registro OIC', icon: 'pi pi-fw pi-file', routerLink: ['/esaf/registroContralor'] }
                        ]
                },/*
            {
                label: 'Contralor',
                items: [
                    { label: 'Listado de contraloria', icon: 'pi pi-fw pi-file', routerLink: ['/contralor/listadoContralor'] }
                ]
            },
             {
                label: 'Prime Blocks',
                items: [
                    { label: 'Free Blocks', icon: 'pi pi-fw pi-eye', routerLink: ['/blocks'], badge: 'NEW' },
                    { label: 'All Blocks', icon: 'pi pi-fw pi-globe', url: ['https://www.primefaces.org/primeblocks-ng'], target: '_blank' },
                ]
            },
            {
                label: 'Utilities',
                items: [
                    { label: 'PrimeIcons', icon: 'pi pi-fw pi-prime', routerLink: ['/utilities/icons'] },
                    { label: 'PrimeFlex', icon: 'pi pi-fw pi-desktop', url: ['https://www.primefaces.org/primeflex/'], target: '_blank' },
                ]
            },
            {
                label: 'Pages',
                icon: 'pi pi-fw pi-briefcase',
                items: [
                    {
                        label: 'Landing',
                        icon: 'pi pi-fw pi-globe',
                        routerLink: ['/landing']
                    },
                    {
                        label: 'Auth',
                        icon: 'pi pi-fw pi-user',
                        items: [
                            {
                                label: 'Login',
                                icon: 'pi pi-fw pi-sign-in',
                                routerLink: ['//auth/login']
                            },
                            {
                                label: 'Error',
                                icon: 'pi pi-fw pi-times-circle',
                                routerLink: ['/auth/error']
                            },
                            {
                                label: 'Access Denied',
                                icon: 'pi pi-fw pi-lock',
                                routerLink: ['/auth/access']
                            }
                        ]
                    },
                    {
                        label: 'Crud',
                        icon: 'pi pi-fw pi-pencil',
                        routerLink: ['/pages/crud']
                    },
                    {
                        label: 'Timeline',
                        icon: 'pi pi-fw pi-calendar',
                        routerLink: ['/pages/timeline']
                    },
                    {
                        label: 'Not Found',
                        icon: 'pi pi-fw pi-exclamation-circle',
                        routerLink: ['/notfound']
                    },
                    {
                        label: 'Empty',
                        icon: 'pi pi-fw pi-circle-off',
                        routerLink: ['/pages/empty']
                    },
                ]
            },
            {
                label: 'Hierarchy',
                items: [
                    {
                        label: 'Submenu 1', icon: 'pi pi-fw pi-bookmark',
                        items: [
                            {
                                label: 'Submenu 1.1', icon: 'pi pi-fw pi-bookmark',
                                items: [
                                    { label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-bookmark' },
                                    { label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-bookmark' },
                                    { label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-bookmark' },
                                ]
                            },
                            {
                                label: 'Submenu 1.2', icon: 'pi pi-fw pi-bookmark',
                                items: [
                                    { label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-bookmark' }
                                ]
                            },
                        ]
                    },
                    {
                        label: 'Submenu 2', icon: 'pi pi-fw pi-bookmark',
                        items: [
                            {
                                label: 'Submenu 2.1', icon: 'pi pi-fw pi-bookmark',
                                items: [
                                    { label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-bookmark' },
                                    { label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-bookmark' },
                                ]
                            },
                            {
                                label: 'Submenu 2.2', icon: 'pi pi-fw pi-bookmark',
                                items: [
                                    { label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-bookmark' },
                                ]
                            },
                        ]
                    }
                ]
            },
            {
                label: 'Get Started',
                items: [
                    {
                        label: 'Documentation', icon: 'pi pi-fw pi-question', routerLink: ['/documentation']
                    },
                    {
                        label: 'View Source', icon: 'pi pi-fw pi-search', url: ['https://github.com/primefaces/sakai-ng'], target: '_blank'
                    }
                ]
            } */



            ];
        }
        if (item.tipoUsuario === 2) {
            this.model = [
                {
                    label: 'Inicio',
                    items: [
                        { label: 'Dashboard', icon: 'pi pi-fw pi-home', routerLink: ['/dash'] }
                    ]
                },
                {
                    label: 'Contraloria',
                    items: [
                        { label: 'Solicitudes', icon: 'pi pi-fw pi-file', routerLink: ['/contralor/listadoContralor'] },
                    ]
                },



            ];
        }
        if (item.tipoUsuario === 3) {
            this.model = [
                {
                    label: 'Inicio',
                    items: [
                        { label: 'Dashboard', icon: 'pi pi-fw pi-home', routerLink: ['/dash'] }
                    ]
                },
                /*{
                     label: 'Contraloria',
                     items: [
                         { label: 'Solicitudes', icon: 'pi pi-fw pi-file', routerLink: ['/contralor/listadoContralor'] }
                     ]
                 },*/
                {
                    label: 'ESAF',
                    items: [
                        { label: 'Solicitudes', icon: 'pi pi-fw pi-file', routerLink: ['/esaf/listado'] },
                        { label: 'Registro OIC', icon: 'pi pi-fw pi-file', routerLink: ['/esaf/registroContralor'] }
                    ]
                },
            ];
        }
        if (item.tipoUsuario === 4) {
            this.model = [
                {
                    label: 'Entrega - Recepción',
                    items: [
                        { label: 'Entrega', icon: 'pi pi-fw pi-user-minus', routerLink: ['/solicitud/entrega'] },
                        /*{ label: 'El que recibe', icon: 'pi pi-fw pi-user-plus', routerLink: ['/solicitud/recibe'] },
                        { label: 'Testigos', icon: 'pi pi-fw pi-users', routerLink: ['/solicitud/testigo'] },*/
                        { label: 'Solicitudes', icon: 'pi pi-fw pi-book', routerLink: ['/solicitud/consulta'] },

                    ]
                },
            ];
        }
    }
}
