import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../auth/services/authentication.service';

@Component({
    selector: 'app-activa-usuario',
    templateUrl: './activa-usuario.component.html',
    styleUrls: ['./activa-usuario.component.scss']
})
export class ActivaUsuarioComponent implements OnInit {
    base64data: string;
    linkMe: boolean;

    constructor(
        private route: ActivatedRoute,
        private auth: AuthenticationService,
    ) { }

    ngOnInit(): void {
        this.base64data = this.route.snapshot.paramMap.get('base64data');
        const decodedString = atob(this.base64data);
        const dataArray = decodedString.split('|');
        this.auth.activateUser(dataArray[1]).subscribe((response) => {
            this.linkMe = (response.messageResponse === 'success') ? true : false;
        });
    }
}
