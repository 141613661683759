import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { Message, MessageService, PrimeNGConfig } from 'primeng/api';
import { User } from './models/user';
import { AuthenticationService } from './modules/components/auth/services/authentication.service';
import { LocalStorageService } from './modules/components/auth/services/local-storage.service';
import { LogoutService } from './modules/components/auth/services/logout.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    providers: [MessageService]
})
export class AppComponent {
    config: { version: string };
    menuMode = 'static';
    public loading = false;
    msgs: Message[] = [];
    usuario;


    constructor(
        private primengConfig: PrimeNGConfig,
        public messageService: MessageService,
        private localService: LocalStorageService,
        private auth: AuthenticationService,
        private httpClient: HttpClient,
        private logoutService: LogoutService,

    ) { }

    ngOnInit() {
        this.primengConfig.ripple = true;
        this.httpClient.get('./assets/i18n/es.json').subscribe(data => {
            this.primengConfig.setTranslation(data);
        });
        this.httpClient.get('./assets/config.json').subscribe(data => {
            this.config = { version: data['version'] };
            console.log(this.config.version);
        });

        const headers = new HttpHeaders()
            .set('Cache-Control', 'no-cache')
            .set('Pragma', 'no-cache');

        this.httpClient
            .get<{ version: string }>("./assets/config.json", { headers })
            .subscribe(config => {
                if (config.version !== this.config.version) {
                    location.reload();
                }
            });
    }

    showMessage(tipo: string, titulo: string, mensaje: string) {
        this.msgs = [];
        this.msgs.push({ severity: tipo, summary: titulo, detail: mensaje });
        return this.msgs;
    }
    blockDocument() {
        this.loading = true;
        setTimeout(() => {
            this.loading = false;
        }, 0);
    }
    showLoading() {
        setTimeout(() => {
            this.loading = true;
        }, 0);
    }


    hideLoading() {
        setTimeout(() => {
            this.loading = false;
        }, 100);
    }

    showMensaje(tipo, titulo, cuerpo) {
        this.messageService.add({ severity: tipo, summary: titulo, detail: cuerpo });
    }

    handleError(response: HttpErrorResponse) {
        console.log("handleError", response)
        if (response.error && response.error.detail === 'Token has expired') {
            this.showMensaje('warn', 'Sesión expirada', 'Por favor vuelva a iniciar sesion.');
            this.usuario = null;
            this.logoutService.cerrarSesion();
        } else {
            this.showMensaje('error', 'Ocurrio un Error', 'Ocurrio un error! contatacta al administrador del sistema');
            console.log(response.error);

        }


    }

    dataUser(){
        this.usuario = JSON.parse(localStorage.getItem('usuario'));
        return this.usuario;
    }
}
