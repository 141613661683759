<div *ngIf="linkMe !== undefined" class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
    <div class="flex flex-column align-items-center justify-content-center">
        <img src="assets/demo/images/login/Copia de Imagotipo Horizontal.png" alt="Image" height="100" class="mb-3" style="margin: 0 auto;">
        <div style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, rgba(156, 157, 158, 0.4) 10%, rgba(33, 150, 243, 0) 30%);">
            <div *ngIf="linkMe" class="w-full surface-card py-8 px-5 sm:px-8 flex flex-column align-items-center" style="border-radius:53px">
                <span class="text-blue-500 font-bold text-3xl">Activación</span>
                <h1 class="text-900 font-bold text-3xl lg:text-5xl mb-2">El usuario se activó correctamente</h1>
                <div class="text-600 mb-5">Ya pude iniciar sesión.</div>
                <a [routerLink]="['/auth/login']" class="w-full flex align-items-center mb-5 py-5 border-300 border-bottom-1">
                    <span class="flex justify-content-center align-items-center bg-indigo-400 border-round" style="height:3.5rem; width:3.5rem;">
                        <i class="pi pi-fw pi-unlock text-50 text-2xl"></i>
                    </span>
                    <span class="ml-4 flex flex-column">
                        <span class="text-900 lg:text-xl font-medium mb-0">Inicio de sesión</span>
                        <span class="text-600 lg:text-xl">Acceda al sistema con su usuario y contraseña</span>
                    </span>
                </a>
            </div>
            <div *ngIf="!linkMe" class="w-full surface-card py-8 px-5 sm:px-8 flex flex-column align-items-center" style="border-radius:53px">
                <span class="text-blue-500 font-bold text-3xl">Activación</span>
                <h1 class="text-900 font-bold text-3xl lg:text-5xl mb-2">El usuario no pudo ser activado correctamente</h1>
                <div class="text-600 mb-5">Ocurrió un error.</div>
                <a [routerLink]="['/landing']" class="w-full flex align-items-center mb-5 py-5 border-300 border-bottom-1">
                    <span class="flex justify-content-center align-items-center bg-red-500 border-round" style="height:3.5rem; width:3.5rem;">
                        <i class="pi pi-fw pi-user-minus text-50 text-2xl"></i>
                    </span>
                    <span class="ml-4 flex flex-column">
                        <span class="text-900 lg:text-xl font-medium mb-0">Regresar</span>
                        <span class="text-600 lg:text-xl">Contacte al administrador del sistema.</span>
                    </span>
                </a>
            </div>
        </div>
    </div>
</div>
