import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private url = environment.URL_BACKSAILS;
    helper = new JwtHelperService();

    constructor(
        private http: HttpClient
    ) { }

    login(data): Observable<any> {
        return this.http.post(this.url + '/login', data) as Observable<any>;
    }

    decodeJWT(token) {
        return this.helper.decodeToken(token);
    }

    activateUser(data): Observable<any> {
        return this.http.get(`${this.url}/usuario/activate/${data}`) as Observable<any>;
    }

    getDatosPersona(email): Observable<any> {
        return this.http.get(`${this.url}/Personas?email=${email}&populate=false`);
    }

    recoveryPassword(usuario): Observable<any> {
        return this.http.post(this.url + `/usuario/recover`, usuario) as Observable<any>;
    }
}
