import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BlockUIModule } from 'primeng/blockui';
import { ToastModule } from 'primeng/toast';

import { AppLayoutModule } from './layout/app.layout.module';
import { ProductService } from './modules/service/product.service';
import { CountryService } from './modules/service/country.service';
import { CustomerService } from './modules/service/customer.service';
import { EventService } from './modules/service/event.service';
import { IconService } from './modules/service/icon.service';
import { NodeService } from './modules/service/node.service';
import { PhotoService } from './modules/service/photo.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import { NotfoundComponent } from './modules/components/notfound/notfound.component';
import { ActivaUsuarioComponent } from './modules/components/activa-usuario/activa-usuario.component';
import { PerfilComponent } from './modules/components/perfiles/components/perfil/perfil.component';



@NgModule({
    declarations: [
        AppComponent, NotfoundComponent, ActivaUsuarioComponent,
    ],
    imports: [
        AppRoutingModule,
        AppLayoutModule,
        HttpClientModule,
        BlockUIModule,
        ToastModule,
        CommonModule,
        ReactiveFormsModule,

    ],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        CountryService, CustomerService, EventService, IconService, NodeService,
        PhotoService, ProductService, MessageService,ConfirmationService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
