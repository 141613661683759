import { Component, ElementRef, ViewChild, HostListener } from '@angular/core';
import { AppLayoutComponent } from './app.layout.component';
import { Router } from '@angular/router';

import { MenuItem } from 'primeng/api';
import { LayoutService } from "./service/app.layout.service";

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent {

    items!: MenuItem[]| undefined;

    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

    @ViewChild('topbarmenu') menu!: ElementRef;

    usuario:any;
    opcionesDesplegadas: boolean = false;
    constructor(
        public layoutService: LayoutService,
        public appLayoutComponent: AppLayoutComponent,
        private router: Router,
        private elementRef: ElementRef) { }


        ngOnInit(){
            this.items = [
                {
                    label: 'Perfil',
                    icon: 'pi pi-user',
                    items: [
                        {
                            label: 'Editar',
                            icon: 'pi pi-user-edit',
                            command: () => this.editPerfil()
                        },
                        {

                            label: 'Contraseña',
                            icon: 'pi pi-key',
                            command: () => this.editContrasena()
                        },
                        {

                            label: 'Salir',
                            icon: 'pi pi-sign-in',
                            command: () => this.salir()
                        },
                    ]
                },

            ];

            const usuario = localStorage.getItem('usuario');
            if (usuario) {
                this.usuario = JSON.parse(usuario);
            }
        }
        @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.ocultarOpciones();
    }
  }
  ocultarOpciones() {
    this.opcionesDesplegadas = false;
  }



    salir(){
        this.appLayoutComponent.closeSession();
        this.ocultarOpciones();
    }

    editPerfil(){
        this.router.navigate(['/perfiles/perfil']);
        this.ocultarOpciones();
    }
    editContrasena(){
        this.router.navigate(['/perfiles/contra']);
        this.ocultarOpciones();
    }

    toggleOpciones() {
        this.opcionesDesplegadas = !this.opcionesDesplegadas;
    }
}
